import React, { useEffect, useState } from "react";

/* Config */
import config from "../../app/config.json";

/* Components */
import {
  Banner,
  Container,
  LoadingScreen,
  RefreshRateSlider,
} from "../../utils/components/Common";
import {
  Table,
  CustomCell,
  FilterDropdown,
  ICellProps,
  CustomCellNavigatorMap,
  FilterDropdownNavigatorMap,
  CustomCellStateMap,
  FilterDropdownStateMap,
} from "../user/Workspace.table.elements";

/* APIs - Hooks - Utils */
import { User } from "../user/userApi";
import { useGetParticipantsQuery } from "../participant/participantApi";
import { toastQueryError } from "../../utils/toasts";

type ParticipantListPanelProps = {
  user: User;
  isLoggedIn: boolean;
};

const ParticipantListPanel = ({
  user,
  isLoggedIn,
}: ParticipantListPanelProps): JSX.Element => {
  const [processing, setProcessing] = useState(true);
  const [pollingInterval, setPollingInterval] = useState(
    config.constants.participant.listPanel.refreshRate.maxPollingInterval / 2
  );

  const { data, isFetching, isLoading, isError, error } =
    useGetParticipantsQuery(undefined, {
      pollingInterval: pollingInterval,
    });

  const columns = React.useMemo(
    () => [
      {
        Header: "Study ID",
        accessor: "record_id",
        Cell: ({ value }: ICellProps) => (
          <CustomCell
            type="link"
            linkTo={`${config.urls.participants.detail.url}/${value}`}
            value={value}
          />
        ),
        Filter: "",
      },
      {
        Header: "First Name",
        accessor: "first_name",
        Filter: "",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
        Filter: "",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        Filter: "",
      },
      {
        /* Configuration for current_status field */
        Header: "State",
        accessor: "mailing_state",
        Filter: FilterDropdownStateMap,
        Cell: ({ value }: ICellProps) => <CustomCellStateMap value={value} />,
        filter: "includes",
      },
      {
        /* Configuration for current_status field */
        Header: "Current Status",
        accessor: "status",
        Filter: FilterDropdown,
        filter: "includes",
      },
      {
        Header: "Next Contact Date",
        accessor: "next_contact_date",
        Filter: "",
        /* Custom sorting function for next_contact_date */
        /* eslint-disable */
        sortType: (rowA: any, rowB: any) => {
          const a = rowA.values.next_contact_date;
          const b = rowB.values.next_contact_date;
          /* sort by earliest next_contact_date and put blanks and non-date strings to bottom */
          if (a === "" && b === "") {
            return 0;
          }
          if (a === "") {
            return 1;
          }
          if (b === "") {
            return -1;
          }
          return a > b ? 1 : -1;
        },
      },
      {
        Header: "Allocated Navigator",
        accessor: "allocated_navigator",
        Cell: ({ value }: ICellProps) => (
          <CustomCellNavigatorMap value={value} />
        ),
        Filter: FilterDropdownNavigatorMap,
        filter: "equals",
      },
      {
        Header: "Randomised amount",
        accessor: "randomised_participant",
        Filter: "",
      },
      {
        Header: "Date of Registration",
        accessor: "register_date_time",
        Filter: "",
      },
      {
        Header: "Expired",
        accessor: "has_lapsed",
        Filter: FilterDropdown,
        filter: "includes",
        disableSortBy: true,
      },
    ],
    []
  );

  const onRefetchRateSliderChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPollingInterval(parseInt(e.target.value));
  };

  useEffect(() => {
    if (isFetching || isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isFetching]);

  return (
    <>
      {user && isLoggedIn ? (
        <Container padding="py-6">
          <RefreshRateSlider
            pollingInterval={pollingInterval}
            onRefreshSliderChange={onRefetchRateSliderChange}
          />

          {processing ? <LoadingScreen /> : null}
          {data ? <Table columns={columns} data={data} /> : null}
        </Container>
      ) : (
        <Banner
          heading="Protected Route"
          message="This is a protected route. Authentication required."
        />
      )}
    </>
  );
};

export default ParticipantListPanel;
