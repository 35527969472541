import React, { Dispatch, SetStateAction } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import classNames from "classnames";

/* Config */
import config from "../../app/config.json";

type ContainerProps = {
  padding?: string;
  margin?: string;
  children?: React.ReactNode;
};

export const Container = ({
  padding,
  margin,
  children,
}: ContainerProps): JSX.Element => {
  return (
    <>
      <div className={`${padding ? padding : null} ${margin ? margin : null}`}>
        {children}
      </div>
    </>
  );
};

type HeroSectionProps = {
  heading?: string;
  message?: string;
  children?: React.ReactNode;
};

export const HeroSection = ({
  heading,
  message,
  children,
}: HeroSectionProps): JSX.Element => {
  return (
    <>
      <section className="bg-slate-300 text-gray-600">
        <div className="container mx-auto flex flex-col items-center px-4 py-16 text-center md:py-32 md:px-10 lg:px-32 xl:max-w-3xl">
          <h1 className="text-4xl font-bold leading-done sm:text-5xl">
            {heading}
          </h1>
          <p className="px-8 mt-8 mb-12 text-lg">{message}</p>
          <div className="flex flex-wrap justify-center">{children}</div>
        </div>
      </section>
    </>
  );
};

type BannerProps = {
  alert?: boolean;
  heading?: string;
  message?: string;
  children?: React.ReactNode;
};

export const Banner = ({
  alert,
  heading,
  message,
  children,
}: BannerProps): JSX.Element => {
  return (
    <>
      <div
        className={`${
          !alert
            ? "bg-teal-100 border-teal-500 text-teal-900"
            : "bg-red-100 border-red-500 text-red-900"
        } border-t-4 rounded-b px-4 py-3 shadow-md mx-5 mb-5`}
      >
        <div className="flex">
          <div className="py-1">
            <svg
              className={`fill-current h-6 w-6 ${
                !alert ? "text-teal-500" : "text-red-500"
              } mr-4`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
            </svg>
          </div>
          <div>
            <p className="font-bold">{heading}</p>
            <p className="text-sm">{message}</p>
          </div>
        </div>
        {children}
      </div>
    </>
  );
};

export type IconTypeProps = {
  width: number;
  height: number;
  color: string;
};

export type IconType = (prop: IconTypeProps) => JSX.Element;

type LinkButtonProps = {
  txtColor: string;
  bgColor: string;
  bgHover?: string;
  block?: boolean;
  icon?: IconType;
  linkTo: string;
  children: React.ReactNode;
  redirect?: boolean;
  target?: string;
};

export const LinkButton = ({
  txtColor,
  bgColor,
  bgHover,
  block,
  icon,
  linkTo,
  children,
  redirect,
  target,
}: LinkButtonProps): JSX.Element => {
  const btnClass = classNames(`${txtColor}`, `${bgColor}`, `${bgHover}`, {
    "inline-flex items-center rounded px-4 py-2 m-4 font-semibold flex": true,
    "w-full": block,
  });

  return (
    <>
      {redirect ? (
        <a className={btnClass} href={linkTo} target={target}>
          <span>
            {icon
              ? React.createElement(icon, {
                  width: 12,
                  height: 12,
                  color: "#fefefe",
                })
              : null}
          </span>

          <span className="mx-2">{children}</span>
        </a>
      ) : (
        <NavLink className={btnClass} to={linkTo}>
          <span>
            {icon
              ? React.createElement(icon, {
                  width: 12,
                  height: 12,
                  color: "#fefefe",
                })
              : null}
          </span>

          <span className="mx-2">{children}</span>
        </NavLink>
      )}
    </>
  );
};

type FormButtonProps = {
  txtColor: string;
  bgColor: string;
  bgHover?: string;
  block?: boolean;
  type: "button" | "submit" | "reset" | undefined;
  onClick?: () => void;
  children: React.ReactNode;
};

export const FormButton = ({
  txtColor,
  bgColor,
  bgHover,
  block,
  type,
  onClick,
  children,
}: FormButtonProps): JSX.Element => {
  const btnClass = classNames(`${txtColor}`, `${bgColor}`, `${bgHover}`, {
    "inline-flex items-center rounded max-w-xs px-4 py-2 m-2 font-semibold":
      true,
    "w-full": block,
  });

  return (
    <>
      <button type={type} className={btnClass} onClick={onClick}>
        {children}
      </button>
    </>
  );
};

type ModalProps = {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  header?: string;
  body?: string;
  children?: React.ReactNode;
};

export const Modal = ({
  setModalOpen,
  header,
  body,
  children,
}: ModalProps): JSX.Element => {
  return (
    <>
      <div className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">{header}</h3>
              <button
                className="p-1.5 ml-auto bg-transparent border-0 text-black float-right leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setModalOpen(false)}
              >
                ✖️
              </button>
            </div>
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-slate-500 text-xs leading-relaxed">
                <pre>
                  <code>{body}</code>
                </pre>
              </p>
            </div>
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              {children}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

type LoadingScreenProps = {
  blackOut?: boolean;
};

export const LoadingScreen = ({
  blackOut,
}: LoadingScreenProps): JSX.Element => {
  const loadingClassNames = classNames({
    "flex justify-center items-center h-screen fixed inset-0 z-40": true,
    "bg-black opacity-25": blackOut,
  });
  return (
    <>
      <div className={loadingClassNames}>
        <PropagateLoader color="#36d7b7" loading={true} speedMultiplier={1} />
      </div>
    </>
  );
};

type NavigateBackButtonProps = {
  children?: React.ReactNode;
};

export const NavigateBackButton = ({
  children,
}: NavigateBackButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const buttonClassNames = classNames({
    "bg-indigo-400 w-20 h-15 rounded-full drop-shadow-lg flex justify-center items-center text-white text-m font-semibold hover:bg-indigo-500 hover:drop-shadow-2xl hover:animate-bounce duration-300":
      true,
  });

  return (
    <>
      <button className={buttonClassNames} onClick={() => navigate(-1)}>
        {children}
      </button>
    </>
  );
};

type FloatingContainerProps = {
  children?: React.ReactNode;
  placement?: "bot-right" | "bot-left";
};

export const FloatingContainer = ({
  children,
  placement,
}: FloatingContainerProps): JSX.Element => {
  const containerClassNames = classNames({
    "fixed z-90": true,
    "bottom-3 right-10": placement == "bot-right",
    "bottom-10 left-40": placement == "bot-left",
  });

  return (
    <>
      <div className={containerClassNames}>{children}</div>
    </>
  );
};
type ControlledSelectProps = {
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  selectType?: "yn" | "stateTransitions" | "tfNaLiteral" | "ynLiteral";
  isMultiple?: boolean;
  defaultMultiple?: string[];
};

/* Form Input whos data is not part of form submission but controls other elements within Form UI */
export const ControlledSelect = ({
  disabled,
  onChange,
  selectType,
  isMultiple,
  defaultMultiple,
}: ControlledSelectProps): JSX.Element => {
  const selectClassNames = classNames({
    "mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500":
      true,
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none":
      disabled,
  });

  return (
    <>
      {selectType ? (
        <select
          disabled={disabled}
          placeholder="Select"
          className={selectClassNames}
          onChange={onChange}
          multiple={isMultiple}
          defaultValue={defaultMultiple}
        >
          <option value="">Select</option>
          {config.form.select[`${selectType}`].map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : null}
    </>
  );
};

type ControlledCheckboxProps = {
  value?: number;
  checked?: boolean;
  definition: string;
  description: string;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/* Form Input whos data is not part of form submission but controls other elements within Form UI */
export const ControlledCheckbox = ({
  value,
  checked,
  definition,
  description,
  disabled,
  onChange,
}: ControlledCheckboxProps): JSX.Element => {
  const checkboxClassNames = classNames({
    "border-gray-300 flex-none rounded h-5 w-5": true,
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none":
      disabled,
  });

  const checkboxDefinitionClassNames = classNames({
    "text-gray-600 font-semibold leading-none tracking-wide": true,
  });
  const checkboxDescriptionClassNames = classNames({
    "text-xs text-gray-500 mt-2 leading-4": true,
  });

  return (
    <>
      <div className="flex items-start space-x-3 py-2">
        <input
          type="checkbox"
          value={value}
          checked={checked}
          className={checkboxClassNames}
          onChange={onChange}
          disabled={disabled}
        />
        <div className="flex flex-col">
          <h3 className={checkboxDefinitionClassNames}>{definition}</h3>
          {description ? (
            <p className={checkboxDescriptionClassNames}>{description}</p>
          ) : null}
        </div>
      </div>
    </>
  );
};

type ControlledSliderProps = {
  min: number;
  max: number;
  step: number;
  value: number;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ControlledSlider = ({
  min,
  max,
  step,
  value,
  disabled,
  onChange,
}: ControlledSliderProps): JSX.Element => {
  const sliderClassNames = classNames({
    "mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500":
      true,
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none":
      disabled,
  });

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        className={sliderClassNames}
        onChange={onChange}
      />
    </>
  );
};

type RefreshRateSliderProps = {
  pollingInterval: number;
  onRefreshSliderChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RefreshRateSlider = ({
  pollingInterval,
  onRefreshSliderChange,
}: RefreshRateSliderProps): JSX.Element => {
  return (
    <>
      <Container padding="px-14">
        <h1 className="text-xl font-bold">
          Refresh Rate: {pollingInterval / 1000} seconds
        </h1>
        <div className={`flex px-3 mb-6 md:mb-0 w-64`}>
          Faster
          <ControlledSlider
            min={
              config.constants.participant.listPanel.refreshRate
                .minPollingInterval
            }
            max={
              config.constants.participant.listPanel.refreshRate
                .maxPollingInterval
            }
            step={config.constants.participant.listPanel.refreshRate.step}
            value={pollingInterval}
            onChange={onRefreshSliderChange}
          />
          Slower
        </div>
      </Container>
    </>
  );
};

type PaginationControlsProps = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
};

export const PaginationControls = ({
  canPreviousPage,
  canNextPage,
  gotoPage,
  nextPage,
  previousPage,
  pageCount,
  pageIndex,
  pageSize,
  setPageSize,
}: PaginationControlsProps): JSX.Element => {
  return (
    <>
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center">
          <PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            text="<<"
          />
          <PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
            text="<"
          />
          <PaginationButton
            onClick={nextPage}
            disabled={!canNextPage}
            text=">"
          />
          <PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            text=">>"
          />
        </div>
        <div>
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              type="number"
              max={pageCount}
              min={1}
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="w-16 px-2 py-1 border rounded-md border-slate-300 text-slate-500"
            />
          </span>{" "}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="px-2 py-1 border rounded-md border-slate-300 text-slate-500"
          >
            {config.pagination.pageSizes.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

type PaginationButtonProps = {
  onClick: () => void;
  disabled: boolean;
  text: string;
};

export const PaginationButton = ({
  onClick,
  disabled,
  text,
}: PaginationButtonProps): JSX.Element => {
  return (
    <>
      <button
        onClick={onClick}
        disabled={disabled}
        className="rounded-md border border-slate-300 p-2.5 text-center text-sm transition-all shadow-sm hover:shadow-lg text-slate-600 hover:text-white hover:bg-slate-800 hover:border-slate-800 focus:text-white focus:bg-slate-800 focus:border-slate-800 active:border-slate-800 active:text-white active:bg-slate-800 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
      >
        {text}
      </button>
    </>
  );
};

type MailtoButtonProps = {
  email: string;
  subject: string;
  body: string;
  icon?: IconType;
  children: React.ReactNode;
};

export const MailtoButton = ({
  email,
  subject,
  body,
  children,
  icon,
}: MailtoButtonProps): JSX.Element => {
  const encoded =
    subject || body
      ? `?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
          body
        )}`
      : "";

  return (
    <a
      href={`mailto:${email}${encoded}`}
      className="inline-flex items-center rounded-full px-4 py-2 m-4 font-semibold border border-current text-sky-700 hover:bg-slate-600 hover:text-white"
    >
      <span>
        {icon
          ? React.createElement(icon, {
              width: 12,
              height: 12,
              color: "#2f82a9",
            })
          : null}
      </span>

      <span className="mx-2">{children}</span>
    </a>
  );
};
