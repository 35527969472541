import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IoMailOpenOutline } from "react-icons/io5";

/* Config */
import config from "../../app/config.json";

/* Components */
import {
  Banner,
  Container,
  FormButton,
  LoadingScreen,
  MailtoButton,
  Modal,
} from "../../utils/components/Common";
import {
  Table,
  CustomCell,
  ICellProps,
} from "../user/Workspace.table.elements";

/* APIs - Hooks - Utils */
import { toastQueryError, toastQuerySuccess } from "../../utils/toasts";

import {
  PaymentRecord,
  useConvertPaymentMutation,
  useGetPaymentsByParticipantQuery,
} from "../payments/paymentApi";
import { Participant } from "./participantApi";
import { composeReissueEmail } from "../../utils/templates";
import { User } from "../user/userApi";

interface ParticipantPaymentsTableProps {
  participantPayments: PaymentRecord[];
  participant?: Participant;
  user?: User;
}

const ParticipantPaymentsTable = ({
  participantPayments,
  participant,
  user,
}: ParticipantPaymentsTableProps): JSX.Element => {
  const [confirmDataModalOpen, setConfirmDataModalOpen] = useState(false);
  const [paymentRecord, setPaymentRecord] = useState<PaymentRecord | undefined>(
    undefined
  );
  const [convertPayment] = useConvertPaymentMutation();

  const columns = React.useMemo(
    () => [
      {
        Header: "Payment ID",
        accessor: "id",
        Filter: "",
      },
      {
        Header: "Participant ID",
        accessor: "participant_id",
        Filter: "",
      },
      {
        Header: "Clinician",
        accessor: "clinician",
        Filter: "",
      },
      {
        Header: "Amount",
        accessor: "amount",
        Filter: "",
      },
      {
        Header: "Fee",
        accessor: "fee",
        Filter: "",
      },
      {
        Header: "Payment Date",
        accessor: "payment_date_time",
        Filter: "",
        Cell: ({ value }: ICellProps) => (
          <CustomCell type="date" value={value} />
        ),
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        Filter: "",
      },
      {
        Header: "Payment Type",
        accessor: "payment_type",
        Filter: "",
      },
      {
        Header: "Payment Method",
        accessor: "payment_method",
        Filter: "",
      },
      {
        Header: "Description",
        accessor: "description",
        Filter: "",
      },
      {
        Header: "Action",
        Filter: "",
        Cell: ({ row }: ICellProps) => {
          const isConvertible = (status: string, method: string) => {
            return (
              config.constants.payments.convertible.statuses.includes(status) &&
              config.constants.payments.convertible.methods.includes(method)
            );
          };
          return (
            <>
              {isConvertible(
                row?.original.payment_status,
                row?.original.payment_method
              ) ? (
                <FormButton
                  type="button"
                  txtColor="text-white"
                  bgColor="bg-blue-500"
                  bgHover="hover:bg-blue-600"
                  onClick={() => {
                    setConfirmDataModalOpen(true);
                    setPaymentRecord(row?.original);
                  }}
                >
                  Convert to Physical
                </FormButton>
              ) : null}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <Container padding="py-6">
      {confirmDataModalOpen ? (
        <Modal
          setModalOpen={setConfirmDataModalOpen}
          header="Confirm PaymentRecord Data"
          body={`Please confirm the payment record to convert \n\n ${JSON.stringify(
            paymentRecord,
            null,
            4
          )}`}
        >
          {paymentRecord && participant && user ? (
            <MailtoButton
              /* Suggested by Project team to leave empty */
              email=""
              subject="Reissuing card payment"
              body={composeReissueEmail(paymentRecord, participant, user)}
              icon={IoMailOpenOutline}
            >
              Mail
            </MailtoButton>
          ) : (
            <Banner
              alert
              heading="Error"
              message="Participant or Payment Record not found"
            />
          )}
          <br />
          <FormButton
            type="button"
            txtColor="text-red-500"
            bgColor="bg-transparent"
            onClick={() => setConfirmDataModalOpen(false)}
          >
            CANCEL
          </FormButton>
          <FormButton
            type="submit"
            txtColor="text-white"
            bgColor="bg-emerald-500"
            bgHover="hover:bg-emerald-600"
            onClick={() => {
              if (!paymentRecord) {
                toast.error("Payment record not found.");
                return;
              }
              convertPayment(paymentRecord)
                .unwrap()
                .then((returnedData) => {
                  if (returnedData) {
                    toastQuerySuccess("Payment Record Converted Successfully");
                  }
                })
                .catch((returnedError) => {
                  toastQueryError(returnedError);
                });
              setConfirmDataModalOpen(false);
            }}
          >
            CONFIRM
          </FormButton>
        </Modal>
      ) : null}
      <Table columns={columns} data={participantPayments} />
    </Container>
  );
};

type ParticipantPaymentListProps = {
  participant?: Participant;
  user?: User;
};
export const ParticipantPaymentList = ({
  participant,
  user,
}: ParticipantPaymentListProps): JSX.Element => {
  const [processing, setProcessing] = useState(true);
  const {
    data: participantPayments,
    isFetching,
    isLoading,
    isError,
    error,
  } = useGetPaymentsByParticipantQuery(participant?.record_id);

  useEffect(() => {
    if (isFetching || isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isFetching]);

  return (
    <>
      {processing ? <LoadingScreen /> : null}
      {participantPayments ? (
        <ParticipantPaymentsTable
          participant={participant}
          participantPayments={participantPayments}
          user={user}
        />
      ) : null}
    </>
  );
};
