import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "../../app/customFetchBaseQuery";

export interface PaymentRecord {
  id: number;
  participant_id: number;
  clinician: number | null;
  amount: number;
  fee: number;
  payment_date_time: string;
  payment_status: string;
  payment_type: string;
  payment_method: string;
  description: string;
  user: number;
}

export const paymentApi = createApi({
  reducerPath: "paymentApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["PaymentRecord"],
  endpoints: (builder) => ({
    getPaymentsByParticipant: builder.query<
      PaymentRecord[],
      string | undefined
    >({
      query(id) {
        return {
          url: `payments?participant_id=${id}`,
          method: "GET",
          credentials: "include",
        };
      },
      providesTags(result) {
        return result
          ? [
              ...result.map(({ id }) => ({
                type: "PaymentRecord" as const,
                id: id,
              })),
              { type: "PaymentRecord", id: "LIST" },
            ]
          : ["PaymentRecord"];
      },
    }),
    convertPayment: builder.mutation<PaymentRecord, PaymentRecord>({
      query(payment) {
        return {
          url: `payments/${payment.id}/convert`,
          method: "PUT",
          credentials: "include",
          body: payment,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "PaymentRecord", id },
      ],
    }),
  }),
});

export const { useGetPaymentsByParticipantQuery, useConvertPaymentMutation } =
  paymentApi;
