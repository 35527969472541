/* Config */
import config from "../app/config.json";

/* Components */

/* APIs - Utils */
import { Participant } from "../features/participant/participantApi";
import { PaymentRecord } from "../features/payments/paymentApi";
import { User } from "../features/user/userApi";
import { isEmpty } from "./common";

export const composeReissueEmail = (
  /* paymentRecord currently unused, reserved for possibility of adding INV number field to PaymentRecord model */
  paymentRecord: PaymentRecord,
  participant: Participant,
  user: User
): string => {
  const postalMembers = [
    participant.mailing_street,
    participant.mailing_city,
    config.form.select.state.find(
      (state) => state.value === participant.mailing_state
    )?.label,
    participant.mailing_post_code,
  ];

  if (postalMembers.some(isEmpty)) {
    return "Postal address is incomplete. Please update the participant's address before sending the email.";
  }

  /* Loop postalMembers to generate postalAddress */
  const postalAddress = postalMembers.join(", ");

  /* Generate mobile number, checking if preferred mobile is not empty */
  const mobileNumber = isEmpty(participant.preferred_mobile)
    ? participant.mobile
    : participant.preferred_mobile;

  return `Dear (input name of TR contact),

Can you please reissue a physical card to ${participant.first_name} ${participant.last_name} with any remaining balance from the card/s issued under (INV number) and cancel the original card/s?

The card recipient's mobile number is ${mobileNumber} and postal address is ${postalAddress}.

Many thanks for your assistance,
${user.first_name} ${user.last_name}`;
};
