import React, { useEffect, useState } from "react";

/* Config */
import config from "../../app/config.json";

/* Components */
import {
  Banner,
  Container,
  LinkButton,
  LoadingScreen,
} from "../../utils/components/Common";
import {
  Table,
  CustomCell,
  FilterDropdown,
  ICellProps,
} from "../user/Workspace.table.elements";

/* APIs - Hooks - Utils */
import { User } from "../user/userApi";
import { useGetCliniciansQuery } from "./clinicianApi";
import { toastQueryError } from "../../utils/toasts";

type ClinicianListPanelProps = {
  user?: User;
  isLoggedIn: boolean;
};

const ClinicianListPanel = ({
  isLoggedIn,
}: ClinicianListPanelProps): JSX.Element => {
  const [processing, setProcessing] = useState(true);
  const { data, isFetching, isLoading, isError, error } =
    useGetCliniciansQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "Record ID",
        accessor: "id",
        Cell: ({ value }: ICellProps) => (
          <CustomCell
            type="link"
            linkTo={`${config.urls.clinicians.detail.url}/${value}`}
            value={value}
          />
        ),
      },
      { Header: "First Name", accessor: "first_name" },
      { Header: "Last Name", accessor: "last_name" },
      {
        /* Configuration for consent_status field */
        Header: "Consent Status",
        accessor: "consent_status",
        Filter: FilterDropdown,
        filter: "includes",
        disableSortBy: true,
      },
      { Header: "Work phone number", accessor: "work_phone" },
      { Header: "Medical Centre", accessor: "medical_centre" },
      { Header: "Street Name", accessor: "street" },
      { Header: "City", accessor: "city" },
      { Header: "Post Code", accessor: "post_code" },
      { Header: "State", accessor: "state" },
      {
        Header: "No. of participants allocated",
        accessor: "num_allocated_participants",
      },
    ],
    []
  );

  useEffect(() => {
    if (isFetching || isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isFetching]);

  return (
    <>
      {isLoggedIn ? (
        <Container padding="px-16 py-6">
          <LinkButton
            txtColor="text-white"
            bgColor="bg-blue-500"
            bgHover="hover:bg-blue-600"
            linkTo={`${config.urls.clinicians.add.url}`}
            target="_blank"
            redirect
          >
            Add Clinician
          </LinkButton>
          {processing ? <LoadingScreen /> : null}
          {data ? <Table columns={columns} data={data} /> : null}
        </Container>
      ) : (
        <Banner
          heading="Protected Route"
          message="This is a protected route. Authentication required."
        />
      )}
    </>
  );
};

export default ClinicianListPanel;
